.box-sticky
	.sticky-title
		@apply relative
		&::before
			@apply content-['\f078'] font-awesome text-base text-white leading-none font-light absolute top-1/2 -translate-y-1/2 right-7.5 pointer-events-none md:hidden
		&.active
			&::before
				@apply rotate-180
	.menu-item-icon
		@apply absolute top-0 right-2.5 z-1 w-12.5 h-12.5 bg-transparent text-tiny leading-none text-gray-99/60 transition-all duration-200 ease-linear flex-center cursor-pointer hover:text-primary
		&.active
			@apply rotate-180
	.menu, .product-categories
		@apply hidden md:block
		li
			@apply relative
		.sub-menu, .children
			@apply hidden
		> li
			&.cat-item
				@apply hidden
				&.cat-parent
					@apply block
			> a
				@apply block text-base font-normal leading-[calc(20/16)] text-black uppercase py-3.75 pl-7.5 pr-13 border-b border-solid border-gray-99/25 xl:pl-[calc(33/1920*100rem)] xl:pr-14 xl:hover:text-secondary
			&.active, &.current-menu-item, &.current-category-ancestor, &.current-cat-parent
				> a
					@apply text-primary
				> .menu-item-icon
					@apply text-primary
			&.current-cat-parent
				> a
					@apply text-primary
			&:hover
				> a
					@apply text-primary
				> .menu-item-icon
					@apply xl:text-primary
			&:last-child
				> a
					@apply border-b-0
				> .sub-menu, > .children
					@apply border-t border-solid border-gray-99/25
					> li
						&:last-child
							> a
								@apply border-b-0
			// submenu 2
			> .sub-menu, > .children
				@apply pl-7.5 pr-5 xl:pl-[calc(33/1920*100rem)] xl:pr-5.5
				> li
					> a
						@apply block text-tiny leading-normal py-2.25 normal-case font-normal text-gray-33 border-b border-solid border-gray-99/25 pr-11 xl:hover:text-primary
					> .menu-item-icon
						@apply right-0 w-10 h-10
					&.active, &.current-menu-item, &.current-category-ancestor, &.current-cat, &.current-cat-parent
						> a
							@apply text-primary underline
						> .menu-item-icon
							@apply text-primary
					&:hover
						> a
							@apply text-primary
						> .menu-item-icon
							@apply xl:text-primary
					// submenu 3
					.sub-menu, .children
						@apply pl-7 pr-0 pt-1.25 pb-1.25 space-y-3.25 xl:pl-8
						> li
							> a
								@apply block text-tiny leading-[1.79] normal-case font-normal text-gray-33 border-b border-gray-99/40 xl:hover:text-primary xl:hover:underline
							> .menu-item-icon
								@apply hidden
							> .children
								@apply block
							&.active, &.current-menu-item, &.current-category-ancestor, &.current-cat, &.current-cat-parent
								> a
									@apply text-primary underline
							// submenu other
							> .sub-menu, .children
								@apply pl-3 space-y-2.5
								li
									a
										@apply border-b border-gray-99/40
	.menu
		> li
			&.menu-item-has-children
				> .sub-menu
					> li
						&.menu-item-has-children
							> .sub-menu
								@apply pl-2.5
								> li
									&.menu-item-has-children
										.menu-item-icon
											@apply flex h-6 right-0
	.product-categories
		@apply max-h-[50vh] overflow-y-auto overflow-x-hidden xl:max-h-[80vh]
		&::-webkit-scrollbar
			@apply w-0.5
		&::-webkit-scrollbar-track
			@apply bg-gray-cc
		&::-webkit-scrollbar-thumb
			@apply bg-primary
		&::-webkit-scrollbar-thumb:hover
			@apply bg-secondary

.product-list-section
	.wrap-filter-product
		@apply hidden
