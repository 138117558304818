.news-detail-section
	.full-content
		ul, ol
			list-style-type: revert
			@apply pl-6.5 space-y-4
		h2
			@apply mb-5
		p, h3, h4, ul
			@apply mb-4
		h5, h6
			@apply mb-3
		iframe
			@apply max-w-full
		table
			@apply max-w-full w-full min-w-full text-base xl:text-base
		.table-scroll
			@apply overflow-x-auto overflow-y-auto w-full
