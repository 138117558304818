.woocommerce-pagination
	@apply flex justify-center mt-10
	.nav-pagination
		@apply flex items-center justify-center flex-wrap space-x-1.25
		a, span
			@apply flex items-center justify-center w-12.5 h-12.5 text-base font-bold leading-[1.38] text-secondary border border-secondary bg-white transition-all duration-200 ease-linear xl:hover:bg-secondary xl:hover:text-white
			&.current
				@apply text-white bg-secondary
			&.dots
				@apply xl:hover:bg-white xl:hover:text-secondary

.post-nav, .woocommerce-pagination
	@apply flex justify-center mt-10 #{!important}
	> ul
		@apply border-0 #{!important}
	.pager, .page-numbers
		@apply flex items-center justify-center flex-wrap space-x-1.25 #{!important}
	a, span
		@apply flex items-center justify-center w-12.5 h-12.5 text-base font-bold leading-[1.38] text-secondary border border-secondary bg-white transition-all duration-200 ease-linear xl:hover:bg-secondary xl:hover:text-white #{!important}
		&.active, &.current
			@apply text-white bg-secondary #{!important}
	li
		&.active, &.current
			a, span
				@apply text-white bg-secondary #{!important}
		&.dots
			a, span
				@apply xl:hover:bg-white xl:hover:text-secondary #{!important}
		&.previous, &.next
			a, span
				@apply w-max px-3 #{!important}
