.subscribe
	// @apply min-h-[calc(38/1920*100rem)]
	@apply relative max-w-[calc(320/1920*100rem)]
	.subscribefrm
		@apply relative w-full flex items-center
		input
			@apply w-full bg-transparent rounded-none h-9.5 pl-5 pr-13.5 text-white text-tiny font-normal border-white placeholder:text-white
		input[type="submit"]
			@apply min-w-full w-full p-0 text-transparent text-tiny font-normal border-none transition-all duration-200 ease-linear xl:hover:bg-primary xl:hover:text-transparent
	.form-group
		@apply mb-0
		&.form-submit
			@apply absolute top-1/2 -translate-y-1/2 right-px h-9 w-11 flex-center
			.icon-send
				@apply absolute-center z-1 text-primary pointer-events-none transition-all duration-200 ease-linear
			&:hover
				.icon-send
					@apply text-white
	.wpcf7-spinner
		@apply absolute left-full top-1/2 -translate-y-1/2 z-1
		// span
		// 	@apply absolute top-[calc(100%+1px)] left-0 italic font-light text-white text-tiny leading-[calc(14/12)] font-primary xl:text-xs
			// before
			// @apply before:font-awesome before:not-italic before:mr-1 before:content-["\f071"]
.select-dropdown
	@apply relative max-w-[calc(320/1920*100rem)]
	.select-current
		@apply px-5 py-1.25 h-9.5 flex items-center border border-gray-cc bg-transparent text-white text-tiny font-normal rounded-none cursor-pointer
		// before
		&:before
			@apply absolute font-awesome content-['\f078'] top-1/2 right-3 -translate-y-1/2 text-xl text-primary font-light pointer-events-none
	.select-list
		@apply absolute top-full left-0 w-full py-2.5 z-1 bg-white border border-gray-cc border-t-0 hidden
		a
			@apply px-5 flex items-center py-2.5 text-gray-33 text-tiny font-normal xl:hover:text-primary

.footer-menu
	a
		@apply block text-base leading-[2.14] font-normal xl:text-tiny

.footer-top
	img
		@apply h-full w-auto
