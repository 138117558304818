.document-item
	@apply flex
	.document-icon
		@apply text-[calc(80/1920*100rem)] text-gray-99 leading-none
	.document-content
		@apply flex-1 pl-4.25
	.document-title
		@apply block font-normal mt-0.5 text-tiny leading-[1.43] text-black xl:hover:text-secondary
	.document-download
		@apply block text-tiny leading-none text-primary xl:hover:text-secondary
