header
	.navbar-nav
		@apply hidden xl:block
	.social-list
		@apply hidden md:flex md:items-center
	.search-wrap
		@apply hidden xl:h-full xl:flex-center
		// .searchbox
		// 	@apply xl:h-10
		form
			@apply m-0
	.logo
		img
			@apply w-auto h-full
	.menu
		@apply flex items-center space-x-10
		// @apply flex items-center space-x-12.5
		.menu-item-icon
			@apply hidden
		> li
			@apply h-20 flex-center
			> a
				@apply text-base font-bold h-full flex-center leading-[calc(20/16)] uppercase text-black whitespace-nowrap hover:text-secondary
			// submenu 2
			> .sub-menu
				@apply absolute -z-1 top-full left-1/2 -translate-x-1/2  w-full bg-white flex justify-center w-full max-w-[calc(1440/1920*100rem)] px-3.75 mx-auto h-[calc(567/1920*100rem)] overflow-x-hidden overflow-y-auto shadow pt-10 pb-3.5 opacity-0 translate-y-5 pointer-events-none transition-all duration-200 ease-linear
				&::-webkit-scrollbar
					@apply w-1
				&::-webkit-scrollbar-track
					@apply bg-white
				&::-webkit-scrollbar-thumb
					@apply bg-secondary
				&::-webkit-scrollbar-thumb:hover
					@apply bg-primary
				> li
					@apply px-5 h-max border-r border-solid border-gray-99/60 w-[calc(240/1920*100rem)] last:border-r-0
					> a
						@apply block text-base leading-normal uppercase font-bold text-secondary
					&.active, &.current-menu-item
						> a
							@apply text-primary
					&:hover
						> a
							@apply text-primary
					// submenu 3
					> .sub-menu
						@apply mt-6 space-y-3.5
						> li
							> a
								@apply block text-tiny leading-[1.79] normal-case font-medium text-gray-66 hover:text-primary hover:underline
							&.active, &.current-menu-item
								> a
									@apply text-primary underline
					// &:last-child
					// 	> .sub-menu
					// 		@apply border-r-0
			&:hover
				> .sub-menu
					@apply opacity-100 pointer-events-auto translate-y-0
			&.active, &.current-menu-item
				> a
					@apply text-primary
	.language
		ul
			@apply flex items-center
		a
			@apply inline-block text-base text-black leading-[calc(20/16)] xl:hover:text-primary
		li
			@apply pl-2 relative first:pl-0 before:content-['|'] before:text-base before:text-black before:leading-[calc(20/16)] first:before:content-none before:absolute before:left-0.5 before:top-1/2 before:-translate-y-1/2
			&.current-lang
				a
					@apply text-primary
	.button-search
		@apply text-xl leading-none w-10 h-10 ml-5 flex-center xl:hidden

.search-wrap
	@apply relative w-full max-w-[calc(320/1920*100rem)] md:w-[calc(270/1920*100rem)] xl:w-[calc(255/1920*100rem)]
	.searchbox
		@apply relative w-full
		input
			@apply w-full bg-gray-f5 rounded-full h-10 px-5 text-black text-tiny font-normal border-none placeholder:text-gray-66
			&::-webkit-search-decoration,
			&::-webkit-search-cancel-button,
			&::-webkit-search-results-button,
			&::-webkit-search-results-decoration
				-webkit-appearance: none
		button
			@apply absolute top-1/2 -translate-y-1/2 right-5.5 p-0 m-0 text-base leading-none text-[#08093D] bg-transparent border-none cursor-pointer z-1 xl:hover:text-secondary
			em, i
				&::before
					content: '\f002'

.header-mobile-wrap
	&.open
		@apply left-0 opacity-100 pointer-events-auto
	.navbar-nav
		@apply h-[72vh] overflow-x-hidden overflow-y-auto pr-7.5
	.menu-item-icon
		@apply absolute top-0 right-0 z-1 w-10 h-10 bg-transparent text-base leading-none text-black transition-all duration-200 ease-linear flex-center
		&.active
			@apply rotate-180
	.menu
		@apply space-y-2.5
		.sub-menu
			@apply hidden
		> li
			@apply relative
			> a
				@apply block py-2.5 text-lg font-bold leading-[calc(20/16)] uppercase text-black
			&.active
				> a
					@apply pl-3 border-l-2 border-solid border-secondary text-secondary
				> .menu-item-icon
					@apply text-secondary
			&:last-child
				> a
					@apply border-b-0
				> .sub-menu
					> li
						&:last-child
							> a
								@apply border-b-0
			// submenu 2
			> .sub-menu
				@apply pl-5 mt-2
				> li
					@apply relative
					> a
						@apply block text-base py-2.5 leading-normal font-bold text-gray-33 uppercase pr-10
					> .menu-item-icon
						@apply text-gray-33
					&.active
						> a
							@apply text-primary
						> .menu-item-icon
							@apply text-primary
					// submenu 3
					> .sub-menu
						@apply pl-5 pt-2.5 pb-5 space-y-3.25
						> li
							> a
								@apply block text-base leading-[1.79] normal-case font-normal text-gray-66
							&.active
								> a
									@apply text-primary
	.search-wrap
		@apply mt-10 mr-7.5
	.social-list
		@apply mt-10 flex items-center justify-center mr-0 space-x-7.5
		a
			@apply text-2xl

.header-search-mobile-wrap
	&.open
		@apply opacity-100 pointer-events-auto scale-100 translate-y-0
	.search-wrap
		@apply max-w-full
