.product-reviews
	div#reviews.woocommerce-Reviews, div#review_form_wrapper
		@apply max-w-[calc(1185/1920*100rem)]
	.woocommerce-Reviews-title
		@apply text-2xl font-bold text-secondary leading-normal mb-5
	.star_box
		@apply p-0 rounded-none border-none my-0 md:block
	.star_box_left
		@apply my-0 -mt-5 md:flex
		> *
			@apply mt-5
		.star-average
			@apply md:w-[calc(300/1920*100rem)] md:pr-5 md:border-r-2 md:border-[#E0E0E0]
		.reviews_bar
			@apply md:flex-1 md:pl-12.5
	.woocommerce-product-rating
		@apply text-center
	span.star_average
		@apply text-center text-[calc(50/1920*100rem)] font-bold leading-none text-secondary block mx-auto mb-3 w-full
	.star-rating
		@apply text-lg leading-normal text-secondary mx-auto mb-2 min-h-[20px] h-6 #{!important}
		span
			@apply w-full
	.woocommerce-review-link
		@apply text-base font-normal leading-normal text-[#1D1D1B] font-normal text-center w-full
	.devvn_review_row
		.devvn_stars_value
			@apply text-tiny leading-[1.43] font-bold text-gray-33
			.devvn-star
				@apply md:ml-3 xl:ml-3.75 xl:mr-6
				@apply before:font-awesome before:content-['\f005'] before:font-bold before:text-lg before:text-[#C4C4C4]
		.devvn_rating_bar
			@apply xl:pl-0 xl:pr-15
			.devvn_scala_rating
				@apply bg-[#EBE5DF] h-[4px] py-0 relative rounded-full #{!important}
			.devvn_perc_rating
				@apply bg-primary h-[4px] py-0 absolute top-0 left-0 z-1 rounded-full #{!important}
		.devvn_num_reviews
			@apply text-base font-normal text-secondary
			b
				@apply font-normal
	.star_box_right
		@apply hidden
		.btn-reviews-now
			@apply text-base text-white font-normal leading-[1.29] space-x-2 border border-solid border-secondary bg-secondary uppercase py-2 px-9 flex items-center justify-center text-center transition-all duration-200 ease-linear h-10 min-w-[calc(220/1920*100rem)] xl:text-lg xl:h-12.5  xl:hover:bg-white xl:hover:text-secondary #{!important}
	.mfp-hide
		@apply block #{!important}
	// form
	div#review_form_wrapper
		@apply p-0 mt-6.5
		> .fancybox-button
			@apply hidden #{!important}
		.comment-reply-title#reply-title
			@apply text-lg font-normal text-gray-33 leading-[1.33] uppercase
		.comment-form-comment
			textarea
				@apply w-full h-25 bg-white border border-[#EBE5DF] rounded-1 py-3.75 px-7.5 text-base font-normal text-gray-33 #{!important}
		.wrap-attaddsend
			@apply flex justify-end bg-white text-xs font-normal text-gray-99
			.review-attach
				@apply hidden #{!important}
		.comment-form-rating
			@apply flex flex-wrap #{!important}
			label
				@apply text-lg font-medium text-gray-33 leading-[1.33] uppercase xl:whitespace-nowrap xl:w-max #{!important}
			.stars
				> span
					@apply w-auto
				a
					@apply text-0 w-10 #{!important}
					// before
					&:before
						@apply text-2xl font-bold font-awesome content-['\f005'] text-primary #{!important}
					&.active
						~ a
							&:before
								@apply text-[#C4C4C4] #{!important}
		.comment-form
			.form-submit
				@apply flex justify-start ml-0 #{!important}
				.submit
					@apply text-base text-white font-normal leading-[1.29] space-x-2 border border-solid border-secondary bg-secondary uppercase py-2 px-9 text-center transition-all duration-200 ease-linear h-12.5 min-w-[calc(220/1920*100rem)] m-0 xl:text-lg xl:h-12.5  xl:hover:bg-white xl:hover:text-secondary #{!important}
	div#reviews
		@apply border-0 #{!important}
	.form_row_reviews
		input
			@apply w-full bg-white border border-solid border-gray-e5 rounded-none h-12.5 px-6 text-tiny text-black font-normal placeholder:text-black read-only:bg-gray-e5 read-only:text-gray-99 disabled:bg-gray-e5 disabled:text-gray-99 #{!important}
	.comment-form-cookies-consent
		input[type="checkbox"]
			@apply p-0 w-3 h-3 #{!important}
		label
			@apply text-base text-blue mb-1 xl:text-tiny #{!important}
	.commentlist
		.review
			@apply mt-5 pt-5 border-t border-solid border-[#E0E0E0] mb-0 #{!important}
		.comment_container
			.devvn_review_top
				@apply mb-1 #{!important}
				.woocommerce-review__author
					@apply text-xl font-bold text-black leading-[1.2] #{!important}
			.devvn_review_mid
				.product-reviews .star-rating
					@apply min-h-[20px] h-6 #{!important}
				.description
					@apply block w-full text-lg font-normal text-black leading-[1.33] #{!important}
			.devvn_review_bottom
				@apply hidden #{!important}

	.devvn_prod_cmt
		@apply hidden #{!important}
