#preload
	&.active
		@apply pointer-events-none
		#panel-left
			transition: all .7s .3s cubic-bezier(0.645, 0.045, 0.355, 1.000)
			@apply -left-full
		#panel-right
			transition: all .7s .3s cubic-bezier(0.645, 0.045, 0.355, 1.000)
			@apply -right-full
		#loader
			@apply opacity-0 transition-all duration-300 ease-out
		#loader-img
			@apply preload-success opacity-0

.preload-success
	@apply invisible -translate-y-full transition-all duration-300 ease-out delay-1000 pointer-events-none

.preload-done
	@apply opacity-0 pointer-events-none transition-all delay-1000 duration-300 ease-out
