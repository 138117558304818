.form-group
	@apply mb-5
	label
		@apply text-base text-blue mb-1 xl:text-tiny
	input[type='text'],
	input[type='email'],
	input[type='url'],
	input[type='password'],
	input[type='number'],
	input[type='date'],
	input[type='datetime-local'],
	input[type='month'],
	input[type='search'],
	input[type='tel'],
	input[type='time'],
	input[type='week'],
	[multiple],
	textarea,
	select
		@apply w-full bg-white border border-solid border-gray-e5 rounded-none h-12.5 px-6 text-tiny text-black font-normal placeholder:text-black read-only:bg-gray-e5 read-only:text-gray-99 disabled:bg-gray-e5 disabled:text-gray-99
	textarea
		@apply h-[calc(180/1920*100rem)] py-3.75 resize-none
	input[type='file']
		@apply hidden
	.button-file-upload
		@apply w-full bg-gray-e5 border border-solid border-gray-e5 rounded-none h-12.5 px-6 text-tiny text-gray-99 font-normal cursor-pointer mb-0
	// > span, > div > span
	// 	@apply text-[12px] text-secondary italic block mt-0.5 font-primary font-normal

	button[type='submit'], input[type='submit']
		@apply min-w-[calc(220/1920*100rem)] bg-secondary border border-solid border-secondary rounded-none h-12.5 px-15 text-lg font-normal text-white uppercase space-x-3 cursor-pointer transition-all duration-200 ease-linear xl:hover:bg-primary
	&.form-file-upload
		> span
			@apply hidden
		> br
			@apply hidden
	.wpcf7-form-control-wrap
		@apply block
	.wpcf7-not-valid-tip
		@apply text-xs text-red italic block font-primary font-normal absolute top-full left-0 z-1 xl:text-[calc(10/1920*100rem)]

.wpcf7
	form
		.wpcf7-response-output
			@apply mx-0 text-base
		&.sent
			.wpcf7-response-output
				@apply text-[#0f5132] bg-[#d1e7dd] border-[#badbcc] rounded-1 border px-4 py-4
		&.invalid
			.wpcf7-response-output
				@apply text-[#842029] bg-[#f8d7da] border-[#f5c2c7] rounded-1 border px-4 py-4
