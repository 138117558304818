@layer utilities
	.container-fluid
		@apply max-w-full px-3.75 xl:px-7.5 #{!important}

	.section-pb
		@apply pt-10 pb-15 xl:pt-[calc(34/1920*100rem)] xl:pb-15

	.section-py
		@apply py-15 xl:pt-[calc(70/1920*100rem)] xl:pb-20

	.section-py-s
		@apply py-15 xl:py-10

	.row
		@apply flex flex-wrap -mx-3.75
		> [class*="col"]
			@apply px-3.75

	.no-gutters
		@apply mx-0
		> [class*="col"]
			@apply px-0

	.writing-mode-tb-rl
		writing-mode: vertical-rl
		text-orientation: mixed

	.media-scale
		@apply relative block h-0 overflow-hidden
		img, iframe, video
			@apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out

	.img-scale
		@apply relative block h-0 overflow-hidden
		img
			@apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover

	.iframe-scale
		@apply relative block h-0 overflow-hidden
		iframe
			@apply absolute min-w-full w-0 h-full top-0 left-0 transition-all duration-300 ease-in-out

	.video-scale
		@apply relative block h-0 overflow-hidden
		video
			@apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover

	.bg-bright-grey
		@apply backdrop-blur-25

	.absolute-center
		@apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2

	.filter-white
		@apply brightness-0 invert

	.img-hover-zoom-in
		&:hover
			img
				@apply scale-110

	.swiper-relative
		@apply relative

	.scroll-custom
		&::-webkit-scrollbar
			@apply xl:w-2
		&::-webkit-scrollbar-track
			@apply xl:bg-gray-f5
		&::-webkit-scrollbar-thumb
			@apply xl:bg-primary
		&::-webkit-scrollbar-thumb:hover
			@apply xl:bg-orange
