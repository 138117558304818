.recruitment-detail
	.information
		.button
			@screen md
				@apply block
				.btn
					@apply mr-0 w-full px-2.5 text-tiny
	.benefit
		i
			@apply text-base leading-normal w-5
	.box-summary
		table
			@apply w-full
		td
			@apply text-base font-normal leading-[1.38] text-black py-3.75 first:font-bold first:text-secondary first:w-max first:pr-2
	.job-description
		@apply mt-7.5
		@apply text-base leading-normal text-black
		h2
			@apply text-lg font-bold text-black leading-[1.22] mt-10
		h3
			@apply text-base font-bold text-gray-66 leading-[1.22]
		ul
			@apply list-disc list-inside space-y-4
		h2
			@apply mb-5
		p, h3, h4, ul
			@apply mb-4
		h5, h6
			@apply mb-3
		iframe
			@apply max-w-full
		table
			@apply max-w-full w-full min-w-full text-base xl:text-base
		.table-scroll
			@apply overflow-x-auto overflow-y-auto w-full
	.button-sticky
		&.active
			@apply opacity-100 pointer-events-auto bottom-0
