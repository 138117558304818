.news-nav-link
	.menu
		@apply flex overflow-x-auto overflow-y-hidden py-5 xl:py-5.5 xl:justify-center
		a
			@apply block w-max text-lg leading-[calc(22/18)] font-normal text-gray-66 pr-4.5 xl:hover:text-primary xl:hover:underline
		li
			@apply flex items-center mr-4.5 text-lg font-normal text-gray-66 after:content-['|'] leading-[calc(22/18)] last:mr-0 last:after:hidden
			&:last-child
				a
					@apply pr-0
			&.active
				a
					@apply text-primary underline
