.video-item
	.video-image
		@apply img-scale pt-[calc(227/358*100%)]
		// before
		@apply before:font-awesome before:content-['\f144'] before:font-bold before:text-primary before:z-1 before:absolute-center before:text-[calc(40/1920*100rem)] before:transition-all before:duration-200 before:ease-linear before:pointer-events-none
		&:hover
			// before
			@apply before:opacity-50
	.video-caption
		@apply mt-5
	.video-title
		@apply text-base font-bold leading-[calc(20/16)] text-black xl:hover:text-primary
