@import 'src/components/_core/_mixins'

.edit-link i:before
	content: '✏️'

body
	overflow-y: overlay
	&::-webkit-scrollbar
		@apply xl:w-1.5
	&::-webkit-scrollbar-track
		@apply xl:bg-gray-f5
	&::-webkit-scrollbar-thumb
		@apply xl:bg-primary
	&::-webkit-scrollbar-thumb:hover
		@apply xl:bg-orange

.date
	@apply text-tiny leading-[calc(17/14)] font-normal text-gray-66 not-italic

.backdrop
	&.show
		@apply pointer-events-auto opacity-100

.nav-fixed
	li
		@apply relative  mb-2.5
		a
			@apply flex-center z-1 w-12.5 h-12.5 p-1 rounded-full bg-primary shadow text-xl text-white p-2.5 border border-solid border-primary xl:border-2 xl:text-[calc(32/1920*100rem)] xl:w-15 xl:h-15 xl:hover:bg-white xl:hover:text-primary
			&.back-to-top
				@apply bg-white text-primary opacity-0 pointer-events-none xl:hover:bg-secondary xl:hover:border-secondary xl:hover:text-white
				&.active
					@apply opacity-100 pointer-events-auto
		&.zalo
			a
				@apply xl:hover:bg-secondary xl:hover:border-secondary
