.news-item
	.news-image
		@apply img-scale pt-[calc(255/450*100%)] border border-gray-cc
		&:hover
			img
				@apply scale-110
	.news-caption
		@apply mt-5 xl:mt-6.25
	.news-date
		@apply mb-2.75
	.news-title
		@apply text-lg font-medium leading-[1.44] text-black line-clamp-2 h-13 xl:hover:text-primary
	.news-desc
		@apply mt-4 text-base font-normal leading-normal text-black line-clamp-3
	.news-button
		@apply mt-3 transition-all duration-200 ease-linear xl:opacity-0 xl:translate-y-10
	@screen xl
		&:hover
			.news-button
				@apply opacity-100 translate-y-0

.news-list-wrap
	.news-item
		.news-desc
			@apply hidden
		&.news-big-item
			.news-desc
				@apply block
