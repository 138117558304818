// Button
@keyframes animatedGradient
	0%
		background-position: 0% 50%

	50%
		background-position: 100% 50%

	100%
		background-position: 0% 50%

// phone ring
@keyframes phoneRing
	0%
		transform: translate(-50%, -50%) scale(.7)
	50%
		transform: translate(-50%, -50%) scale(1.1)
	100%
		transform: translate(-50%, -50%) scale(.7)

// global title
@keyframes slide-in
	from
		transform: translateY(-1em) rotate(-.5turn) scale(0.5)
		opacity: 0

@keyframes productTranslate1
	0%
		transform: translateY(0)

	30%
		transform: translateY(-10px)

	50%
		transform: translateY(4px)

	70%
		transform: translateY(-15px)

	100%
		transform: translateY(0)

@keyframes backgroundFade
	0%
		opacity: 0

	30%
		opacity: .4

	50%
		opacity: 1

	70%
		opacity: .6

	100%
		opacity: .3

// preload animation
@keyframes gradient
	0%
		background-position: 0% 50%

	50%
		background-position: 100% 50%

	100%
		background-position: 0% 50%

@keyframes alo-circle-anim
	0%
		@apply scale-70 skew-x-1 skew-y-1 opacity-10
	30%
		@apply scale-125 skew-x-1 skew-y-1 opacity-50
	100%
		@apply scale-150 skew-x-1 skew-y-1 opacity-10

@keyframes alo-circle-fill-anim
	0%
		@apply rotate-0 scale-70 skew-x-1 skew-y-1 opacity-20
	50%
		@apply rotate-0 scale-100 skew-x-1 skew-y-1 opacity-20
	100%
		@apply rotate-0 scale-70 skew-x-1 skew-y-1 opacity-20

// .animate(data-animate="animLeft 1s")
.animate[data-animate]
	@apply opacity-0
	&.animate-success
		@apply opacity-100 transition-all
@keyframes animTitle
	from
		transform: translateX(-100px)
		opacity: 0
	to
		transform: none
		opacity: 1
@keyframes animContent
	from
		transform: translateX(50px)
		opacity: 0
	to
		transform: none
		opacity: 1
@keyframes animTop
	from
		opacity: 0
		transform: translateY(80px)
	to
		transform: none
		opacity: 1
@keyframes animBottom
	from
		opacity: 0
		transform: translateY(-80px)
	to
		transform: none
		opacity: 1
@keyframes animLeft
	from
		opacity: 0
		transform: translateX(80px)
	to
		transform: none
		opacity: 1
@keyframes animRight
	from
		opacity: 0
		transform: translateX(-80px)
	to
		transform: none
		opacity: 1
@keyframes animZoomIn
	from
		opacity: 0
		transform: scale(0.8)
	to
		transform: none
		opacity: 1
@keyframes animZoomInTop
	from
		opacity: 0
		transform: scale(0.8) translateY(80px)
	to
		transform: none
		opacity: 1
@keyframes animZoomInBottom
	from
		opacity: 0
		transform: scale(0.8) translateY(-80px)
	to
		transform: none
		opacity: 1
@keyframes animZoomInLeft
	from
		opacity: 0
		transform: scale(0.8) translateX(80px)
	to
		transform: none
		opacity: 1
@keyframes animZoomInRight
	from
		opacity: 0
		transform: scale(0.8) translateX(-80px)
	to
		transform: none
		opacity: 1
@keyframes animZoomOut
	from
		opacity: 0
		transform: scale(1.2)
	to
		transform: none
		opacity: 1
@keyframes animZoomOutTop
	from
		opacity: 0
		transform: scale(1.2) translateY(80px)
	to
		transform: none
		opacity: 1
@keyframes animZoomOutBottom
	from
		opacity: 0
		transform: scale(1.2) translateY(-80px)
	to
		transform: none
		opacity: 1
@keyframes animZoomOutLeft
	from
		opacity: 0
		transform: scale(1.2) translateX(80px)
	to
		transform: none
		opacity: 1
@keyframes animZoomOutRight
	from
		opacity: 0
		transform: scale(1.2) translateX(-80px)
	to
		transform: none
		opacity: 1
@keyframes animTopLeft
	from
		opacity: 0
		transform: translateX(80px) translateY(80px)
	to
		transform: none
		opacity: 1
@keyframes animTopRight
	from
		opacity: 0
		transform: translateX(-80px) translateY(80px)
	to
		transform: none
		opacity: 1
@keyframes animBottomLeft
	from
		opacity: 0
		transform: translateX(80px) translateY(-80px)
	to
		transform: none
		opacity: 1
@keyframes animBottomRight
	from
		opacity: 0
		transform: translateX(-80px) translateY(-80px)
	to
		transform: none
		opacity: 1
