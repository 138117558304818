.product-detail-slider
	.swiper-top, .swiper-thumbs
		.swiper-wrapper
			@apply h-auto
	.swiper-button
		.button-prev
			@apply text-gray-99 bg-transparent border-transparent left-0 md:top-0 md:left-1/2 md:-translate-x-1/2 md:translate-y-0
			i
				@apply md:text-[calc(30/1920*100rem)] md:before:content-['\f106']
		.button-next
			@apply text-gray-99 bg-transparent border-transparent right-0 md:top-auto md:bottom-0 md:left-1/2 md:-translate-x-1/2 md:translate-y-0
			i
				@apply md:text-[calc(30/1920*100rem)] md:before:content-['\f107']
	.swiper-thumbs
		.swiper-slide-thumb-active
			.image
				@apply border-secondary

.product-detail
	.product-share
		padding: r(2px) r(12px)
		@apply bg-[#557efc] inline-flex rounded-[calc(16/1920*100rem)]
		span
			@apply text-tiny text-white
	.specifications
		table
			@apply w-full
			tr
				@apply bg-white odd:bg-gray-f3
			td,th
				@apply text-lg leading-[calc(22/18)] font-normal text-black py-3.5 px-5 first:font-semibold
	.tabslet-tabs
		@apply flex items-center overflow-x-auto overflow-y-hidden
		a
			@apply block w-max text-lg leading-[calc(22/18)] font-normal uppercase text-gray-66 py-3.5 px-5 bg-gray-f5 xl:hover:bg-secondary xl:hover:text-white
		li
			@apply mr-2 last:mr-0
			&.active
				a
					@apply text-white bg-secondary

	.document-wrap
		@apply xl:-mx-[calc((65/2)/1920*100rem)]
		> *
			@apply xl:px-[calc((65/2)/1920*100rem)]
	.full-content
		@apply relative transition-all duration-200 ease-linear text-base xl:text-lg
		h2
			@apply mb-5
		p, h3, h4, ul
			@apply mb-4
		h5, h6
			@apply mb-3
		ul, ol
			list-style-type: revert
			@apply pl-6.5 space-y-4
		table
			@apply max-w-full w-full min-w-full text-base xl:text-base
		td
			@apply border border-gray-ee px-3
		iframe
			@apply max-w-full
		.table-scroll
			@apply overflow-x-auto overflow-y-auto w-full
		.document-item
			.date
				@apply mb-0
		.video-item
			.video-image
				@apply bg-black shadow-svg
				img
					@apply opacity-40
		&.max-height
			@apply max-h-[calc(1000/1920*100rem)] overflow-hidden
		&.active
			@apply overflow-visible max-h-fit pb-50

	.view-full-content
		background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.896796218487395) 80%, rgba(255,255,255,0) 100%)
		@apply absolute left-0 bottom-0 w-full z-30 mb-0 pt-10 pb-5 transition-all duration-200 ease-linear
		.text-view-less
			@apply hidden
		&.active
			// @apply opacity-0 pointer-events-none
			.text-view-more
				@apply hidden
			.text-view-less
				@apply block
			i
				@apply rotate-180
