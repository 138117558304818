.about-detail
	.global-desc
		h2
			@apply mb-5
		p, h3, h4, ul
			@apply mb-4
		h5, h6
			@apply mb-3
		iframe
			@apply max-w-full
		table
			@apply max-w-full w-full min-w-full text-base xl:text-base
		th, td
			@apply border p-2.5
		.table-scroll
			@apply overflow-x-auto overflow-y-auto w-full
