.modal
	@apply p-0 hidden max-w-[calc(1410/1920*100rem)] mx-auto
	> .carousel__button
		@apply xl:top-0 xl:text-gray-99 #{!important}
		svg
			@apply xl:filter-none #{!important}

.modal-apply-recruitment
	@apply max-w-[calc(855/1920*100rem)]
	.wrap-form
		.row
			@apply -mt-5 md:-mx-2.5
			> *
				@apply mt-5 md:px-2.5
	.form-group
		@apply mb-0
		textarea
			@apply h-[calc(135/1920*100rem)]
		button[type='submit'], input[type='submit']
			@apply bg-primary border-primary text-white font-bold rounded-full xl:h-10 xl:text-tiny
		.button-file-upload
			@apply rounded-full xl:h-10
.modal-product-advice
	.wrap-item
		.item
			@apply md:max-w-[calc(250/1920*100rem)] xl:h-[calc(250/1920*100rem)]
