@layer components
	// button toggle menu
	#buttonMenu
		@apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none bg-none ml-7.5
		.line
			@apply absolute block h-[2px] w-full bg-secondary rounded-full opacity-100 left-0 rotate-0 transition-all
			&:nth-child(1)
				@apply top-[4px]
			&:nth-child(2)
				@apply top-1/2 -translate-y-1/2
			&:nth-child(3)
				@apply bottom-[4px]
		#pulseMe
			@apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1
		.bar
			@apply bg-primary rounded-0.5 absolute shadow-bar
			&.left
				@apply w-[1px] animate-left-bar
			&.top
				@apply h-[1px] animate-top-bar
			&.right
				@apply w-[1px] animate-right-bar
			&.bottom
				@apply h-[1px] animate-bottom-bar
		&.open, &:hover
			.line
				&:nth-child(2)
					@apply w-[15px]
			.bar
				@apply hidden
		@screen xl
			@apply hidden

	// link a hover underline animation
	.hover-underline
		@apply relative transition-all duration-200 bg-no-repeat bg-underline

	// Lib - Swiper: Pagination white
	.swiper, .swiper-relative
		.swiper-pagination
			@apply -bottom-5
			.swiper-pagination-bullet
				@apply opacity-50 mx-1.25 w-2.5 h-2.5 rounded-none transition-all duration-200 ease-linear
				&.swiper-pagination-bullet-active
					@apply opacity-100 w-5
		.swiper-pagination-white
			.swiper-pagination-bullet
				@apply bg-white
		.swiper-pagination-primary
			.swiper-pagination-bullet
				@apply bg-primary
		.swiper-pagination-secondary
			.swiper-pagination-bullet
				@apply bg-secondary
		.button-prev, .button-next
			@apply absolute top-1/2 -translate-y-1/2 z-1 rounded-full border border-solid border-gray-e5 flex-center cursor-pointer transition duration-200 ease-linear w-10 h-10 text-base text-gray-66 bg-gray-e5 xl:hover:bg-primary xl:hover:border-primary xl:hover:text-white
			&.swiper-button-disabled
				@apply opacity-50
			&.swiper-button-lock
				@apply opacity-0 pointer-events-none
			&.top-35
				@apply top-[35%]
			&.top-45
				@apply top-[45%]
			&.button-transparent
				@apply bg-transparent text-white border-white xl:hover:bg-secondary xl:hover:border-secondary xl:hover:text-white
			&.button-white
				@apply bg-white text-white border-white xl:hover:bg-secondary xl:hover:border-secondary xl:hover:text-white
			&.button-50
				@apply xl:w-12.5 xl:h-12.5

	// button
	.button
		@apply flex flex-wrap items-center
		> *
			@apply mr-2.5 last:mr-0 xl:mr-5.5 last:mr-0
		.link
			@apply inline-block text-base font-normal leading-[1.29] text-black space-x-2 transition-all duration-200 ease-linear xl:text-tiny
			i, em
				@apply leading-none transition-all duration-200 ease-linear
			&.link-primary
				@apply text-primary xl:hover:space-x-3
				span
					@apply hover-underline bg-underline-ratio-primary
				&:hover
					span
						@apply xl:hover:bg-underline-full
			&.link-secondary
				@apply text-secondary xl:hover:space-x-3
				span
					@apply hover-underline bg-underline-ratio-secondary
				&:hover
					span
						@apply xl:hover:bg-underline-full
			&.link-bold
				@apply font-bold
		.btn
			@apply text-base font-normal leading-[1.29] text-black space-x-2 border border-solid border-transparent bg-white uppercase py-2 px-9 flex items-center justify-center text-center transition-all duration-200 ease-linear h-10 min-w-[calc(220/1920*100rem)] xl:text-lg xl:h-12.5
			i, em
				@apply leading-none transition-all duration-200 ease-linear
			&.btn-primary
				@apply border-primary text-primary xl:hover:bg-primary xl:hover:text-white
				&.btn-bg-primary
					@apply bg-primary text-white xl:hover:bg-white xl:hover:text-primary
			&.btn-secondary
				@apply border-secondary text-secondary xl:hover:bg-secondary xl:hover:text-white
				&.btn-bg-secondary
					@apply bg-secondary text-white xl:hover:bg-white xl:hover:text-secondary
			&.btn-rounded-full
				@apply rounded-full font-bold h-10 px-8 min-w-[calc(180/1920*100rem)] text-base xl:border-2 xl:text-base
				&.btn-bg-primary
					@apply bg-primary text-white xl:hover:bg-white xl:hover:text-primary
				&.btn-bg-secondary
					@apply bg-secondary text-white xl:hover:bg-white xl:hover:text-secondary

	// Global title
	.global-title
		@apply text-2xl font-extrabold text-black uppercase leading-[calc(44/36)] xl:text-3xl

	// Secondary title
	.secondary-title
		@apply text-2xl font-bold text-secondary uppercase leading-[1.42]

	// Global description
	.global-desc
		@apply text-lg text-gray-33 space-y-4 font-normal leading-[1.44]
		ul, ol
			list-style-type: revert
			@apply pl-6.5 space-y-4
