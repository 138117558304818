.product-item
	@apply p-5
	.product-image
		@apply img-scale pt-full
	.product-caption
		@apply mt-7.5
	.product-title
		@apply text-base font-bold leading-[1.38] text-gray-33 line-clamp-2 h-11 xl:hover:text-primary
	.product-modal
		@apply mt-7.5 text-tiny font-normal leading-[1.29] text-gray-33 xl:mt-8.5
		span
			@apply text-secondary
	.product-button
		@apply mt-3 transition-all duration-200 ease-linear xl:opacity-0 xl:translate-y-10
	@screen xl
		&:hover
			.product-button
				@apply opacity-100 translate-y-0

.product-horizontal-item
	@apply p-2.5 shadow-product-horizontal transition-all duration-200 ease-linear xl:p-5.5 xl:hover:shadow-product-horizontal-hover
	.product-image
		@apply img-scale pt-full
	.product-title
		@apply block text-xl font-bold leading-[1.1] text-black xl:hover:text-primary
	.product-modal
		@apply mt-5 text-base font-normal leading-[1.12] text-black
		span
			@apply text-secondary
	.product-desc
		@apply mt-5 text-base leading-[1.88] font-normal text-black
		ul
			@apply list-disc list-inside pl-5
			li
				@apply list-disc font-normal
		strong
			@apply font-normal
	.product-button
		@apply mt-6 xl:mt-8
	@screen md
		@apply flex
		.product-image
			@apply pt-0 w-[calc(250/1920*100rem)] h-[calc(250/1920*100rem)]
		.product-caption
			@apply flex-1 pl-7.5
