.home-banner
	.button
		.btn
			@apply font-normal
	.swiper-pagination
		@apply md:left-0 md:translate-x-0 md:text-left md:bottom-5 xl:bottom-27
		.swiper-pagination-bullet
			@apply md:bg-white md:ml-0 md:mr-2.5 md:last:mr-0 md:opacity-100
			&.swiper-pagination-bullet-active
				@apply md:bg-primary
	.swiper-slide
		h2, .desc, .button
			@apply xl:transition-all xl:duration-500 xl:ease-linear xl:opacity-0
		&.swiper-slide-active
			h2, .desc, .button
				@apply xl:translate-x-0 xl:opacity-100
			h2
				@apply xl:delay-100
			.desc
				@apply xl:delay-200
			.button
				@apply xl:delay-300
