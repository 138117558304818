.box-recruitment-1
	@apply bg-[url('../img/bg-1.jpg')] bg-cover bg-center bg-no-repeat
	ul
		@apply list-disc list-inside space-y-4
	h2
		@apply mb-5
	p, h3, h4, ul
		@apply mb-4
	h5, h6
		@apply mb-3
	iframe
		@apply max-w-full
	table
		@apply max-w-full w-full min-w-full text-base xl:text-base

.box-recruitment-2
	ul
		@apply list-disc list-inside space-y-4
	h2
		@apply mb-5
	p, h3, h4, ul
		@apply mb-4
	h5, h6
		@apply mb-3
	iframe
		@apply max-w-full
	table
		@apply max-w-full w-full min-w-full text-base xl:text-base
	.table-scroll
		@apply overflow-x-auto overflow-y-auto w-full
	.item
		@apply text-gray-99 text-center mr-7.5
		i, em
			@apply text-[calc(65/1920*100rem)] leading-none
		.caption
			@apply text-base mt-5 font-bold leading-normal

// .recruitment-wrap
// 	&::-webkit-scrollbar
// 		@apply xl:w-1.5
// 	&::-webkit-scrollbar-track
// 		@apply xl:bg-gray-f5
// 	&::-webkit-scrollbar-thumb
// 		@apply xl:bg-primary
// 	&::-webkit-scrollbar-thumb:hover
// 		@apply xl:bg-orange
.recruitment-wrap
	.swiper-button
		@apply flex justify-center mt-2.5 space-x-2.5 xl:justify-end
		> *
			@apply static translate-y-0 translate-x-0 bg-transparent border-none w-auto h-auto text-2xl xl:text-lg xl:hover:bg-transparent xl:hover:text-primary
