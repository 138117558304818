.accordion-item
	.accordion-header
		@apply text-xl font-bold text-black leading-[calc(24/20)] relative pt-3.75 pb-7 border-b border-solid border-gray-99/25 pr-6.5 cursor-pointer
		// before
		@apply before:absolute before:font-awesome before:content-['\f078'] before:font-light before:top-2 before:right-0 before:w-10 before:h-10 before:flex before:items-center before:justify-center before:text-lg before:text-gray-cc before:z-1 before:pointer-events-none before:transition-transform before:duration-200 before:ease-linear
		&.active
			@apply text-secondary before:text-secondary before:-rotate-180
	.accordion-content
		@apply hidden text-lg leading-[1.67] text-black font-normal pt-8 pb-10 space-y-4.5
		ul
			@apply list-disc list-inside space-y-4.5
		h2
			@apply mb-5
		p, h3, h4, ul
			@apply mb-4
		h5, h6
			@apply mb-3
		iframe
			@apply max-w-full
		table
			@apply max-w-full w-full min-w-full text-base xl:text-base
		.table-scroll
			@apply overflow-x-auto overflow-y-auto w-full
