#breadcrumbs
	@apply flex flex-wrap items-center
	> span
		@apply flex flex-wrap items-center
	> span > span
		&:first-child
			> a
				@apply text-0
				&::before
					@apply font-awesome content-['\f015'] text-tiny font-normal text-gray-99 mr-1
				&:hover
					@apply before:text-primary
		a, &
			@apply text-tiny font-normal leading-[calc(17/14)] text-gray-99 capitalize transition-all duration-200 ease-linear xl:hover:text-primary
		&:not(:first-child)
			@apply inline-block mx-2.5
