.swiper-webgl
	@apply w-full h-full
	img
		@apply max-w-none inline

.home-about-brands
	.swiper-slide
		@apply h-auto

.home-products
	.tabslet-tabs
		.swiper-tabs
			@apply text-lg leading-[calc(22/18)] font-normal not-italic text-gray-66
			&.active
				@apply text-primary underline
		.swiper-slide
			@apply w-max pr-12.5 relative
			// before
			@apply before:absolute before:content-['|'] before:top-1/2 before:-translate-y-1/2 before:right-6 before:w-px before:text-lg before:leading-[calc(22/18)] before:font-normal before:not-italic before:text-gray-66 last:pr-0 last:before:hidden
		.swiper-button
			.button-prev, .button-next
				@apply w-auto h-auto text-gray-66 text-base leading-none bg-none bg-transparent border-none rounded-none
	.tabslet-content
		@apply hidden
	.swiper-home-product
		.swiper-button
			.button-prev, .button-next
				@apply bg-white border-white
			@screen xl
				.button-prev, .button-next
					@apply hover:bg-primary hover:border-primary
		.swiper-wrapper
			@apply h-auto
